import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { Switch,Router } from 'react-router'
import { Route } from 'react-router-dom'
import history from './dependencies/history'
import configureStore from './store/configureStore'
import bootstrap from 'bootstrap'
import PublicIndex from './components/pages/public/PublicIndex'
import HomePageNew from './components/pages/HomePageNew'
import HowItWorksNew from './components/pages/HowItWorksNew'
import PricingNew from './components/pages/PricingNew'
import HelpPageNew from './components/pages/HelpPageNew'
import FaqRecruiter from './components/pages/FaqRecruiter'
import PartnersPageNew from './components/pages/PartnersPageNew'
import TeamPageNew from './components/pages/TeamPageNew'
import HomePageVideo from './components/pages/HomePageVideo'
import SolTalentManage from './components/pages/SolTalentManage'
import SolPowered from './components/pages/SolPowered'
import SolUniv from './components/pages/SolUniv'
import SolTalentAcqui from './components/pages/SolTalentAcqui'
import ArticlesAssessNew from './components/pages/ArticlesAssessNew'
import ArticlesBlueNew from './components/pages/ArticlesBlueNew'
import ArticlesTalentNew from './components/pages/ArticlesTalentNew'
import FrequentlyAskedQuestionsNew from './components/pages/FrequentlyAskedQuestionNew'
import TermOfServicesNew from './components/pages/TermOfServicesNew'
import PrivacyPolicyNew from './components/pages/PrivacyPolicyNew'
import UserRegisterWizardFormContainerNew from './components/pages/UserRegisterWizardFormContainerNew'
import UserRegisterWizardFormContainerNew2 from './components/pages/UserRegisterWizardFormContainerNew2'

import TechVideo from './components/pages/TechVideo'
import TechAssess from './components/pages/TechAssess'
import TechHolistic from './components/pages/TechHolistic'
import TechStructured from './components/pages/TechStructured'
import TechJob from './components/pages/TechJob'
import TechNextgen from './components/pages/TechNextgen'

import EnterCodePage from './components/pages/EnterCodePage'

import PageNotFound from './components/pages/PageNotFound'

import 'font-awesome/css/font-awesome.css'
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'
import 'nprogress/nprogress.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'rc-time-picker/assets/index.css'

import './styles/css/reset.css'
import './styles/css/all.min.css'
import './styles/css/astronaut.main-1.0.scss'
import './styles/sass/style.scss'

import * as serviceWorker from './serviceWorker';

import CandidateSupport from './components/pages/CandidateSupport'
import Feature from './components/pages/Feature'
import DemonstrationPage from './components/pages/DemonstrationPage'
import TrainingPage from './components/pages/TrainingPage'

if (process.env.REACT_APP_API_HOST) {
    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
  }

export const store = configureStore();
const publicIndexTarget = document.getElementById("root")

ReactDOM.render(
    <Provider store={store}>
        <Router history={history}>
            <PublicIndex>
            <Switch>
                <Route exact path='/' component={HomePageNew} />
                <Route exact path='/homepage-video' component={HomePageVideo} />
                <Route exact path='/about' component={TeamPageNew} />
                <Route exact path='/partnership' component={PartnersPageNew} />
                <Route exact path='/how-it-works' component={HowItWorksNew} />
                <Route exact path='/plans' component={PricingNew} />
                <Route exact path='/help' component={HelpPageNew} />
                <Route exact path='/help/faq' component={FaqRecruiter} />
                <Route exact path='/faq' component={FrequentlyAskedQuestionsNew} />
                <Route exact path='/faq/:langId' component={FrequentlyAskedQuestionsNew} />
                <Route exact path='/termsofservice' component={TermOfServicesNew} />
                <Route exact path='/privacypolicy' component={PrivacyPolicyNew} />
                <Route exact path='/solution/talent-management' component={SolTalentManage} />
                <Route exact path='/solution/powered-by-astrnt' component={SolPowered} />
                <Route exact path='/solution/university-admission' component={SolUniv} />
                <Route exact path='/solution/talent-acquisition' component={SolTalentAcqui} />
                <Route exact path='/astronaut-for/assessment-certification' component={ArticlesAssessNew} />
                <Route exact path='/astronaut-for/blue-collar-recruitment' component={ArticlesBlueNew} />
                <Route exact path='/astronaut-for/video-platform' component={ArticlesTalentNew} />
                <Route exact path='/register' component={UserRegisterWizardFormContainerNew} />
                <Route exact path='/registered' component={UserRegisterWizardFormContainerNew2} />
                <Route exact path='/enter-code' component={EnterCodePage} />
                <Route exact path='/candidate-support' component={CandidateSupport} />
                <Route exact path='/features' component={Feature} />
                <Route exact path='/request-demo' component={DemonstrationPage} />
                <Route exact path='/training' component={TrainingPage} />
                <Route exact path='/tech/video-interviewing' component={TechVideo} />
                <Route exact path='/tech/assessments' component={TechAssess} />
                <Route exact path='/tech/holistic-assessments' component={TechHolistic} />
                <Route exact path='/tech/structured-interview-builder' component={TechStructured} />
                <Route exact path='/tech/job-simulations' component={TechJob} />
                <Route exact path='/tech/nextgen-assessment-center' component={TechNextgen} />
                <Route component={PageNotFound} />
            </Switch>
            </PublicIndex>
        </Router>
    </Provider>,
publicIndexTarget
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
